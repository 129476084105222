// React
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'

// Redux
import { RootState } from '../../store/reducer'
import { useDispatch, useSelector } from 'react-redux'

// Actions
import { fetchVenueInfoData, venueInfoUpdated } from '../../store/venueInfo'

// Languages
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

// Axios instances
import { venueManagerApi } from '../../api/axios.config'

// Components
import TagmeModal from '../../Components/TagmeModal'
import TagmeButton from '../../Components/TagmeButton'
import WaitlistHeader from '../../Components/WaitlistHeader'
import PositionIndicator from '../../Components/PositionIndicator'
import DisclamerServiceTerms from '../../Components/DisclamerServiceTerms'

// Styles
import { GlobalStyle } from '../../styles/global'
import { ContainerStyles, FooterStyles, ClientPositionStyles } from './styles'

// Interfaces
import { LanguageTypes } from '../../common/interfaces'
import { SuccessUpdateVenueInfo } from '../../store/interfaces'
import { WaitListInfo, WaitListResponseParams, OperationModeType } from './interfaces'
import { ModalProps, ModalTypes, ButtonTypes } from '../../Components/TagmeModal/interface'

// Utils
import { handleSessionStorage } from '../../utils/sessionStorageManagement'

// Pusher
import { PusherService } from '../../Services/pusher.service'

// Errors
import * as Sentry from '@sentry/react'
import { ErrorsList } from '../../common/errors'

// import '@lottiefiles/lottie-player'
import { COLORS } from '../../common/constants'

//TextsDinamics
import { venuesListSpecial } from '../../common/textDinamic'

// Images
import handleCalled from '../../assets/images/alert-called.svg'
import handleSeated from '../../assets/images/alert-seated.svg'

const pusherService = new PusherService()

function WaitList() {
	const defaultWaitlist = useMemo(
		() => ({
			status: 'active',
			globalPos: 0,
			displayHelpers: {
				partyLabel: '',
				priorityText: '',
			},
			pos: 0,
			priority: null,
			_id: '',
			arrivedAt: '',
			customer: {
				_id: '',
				name: '',
			},
			customerTab: '',
			estimatedTime: '',
			origin: {
				_id: '',
				label: '',
			},
			partySize: 1,
			venue: '',
			hideCustomerPosition: false,
			operationMode: OperationModeType.ADVANCED,
		}),
		[]
	)

	const [modalInfo, setModalInfo] = useState<ModalProps>({
		show: false,
		type: ModalTypes.error,
	})

	const [waitlistState, setWaitlistState] = useState<WaitListInfo>(defaultWaitlist)

	const [waitlistPusher, setWaitlistPusherState] = useState(false)

	const [hasBeenCalled, setHasBeenCalled] = useState<boolean>(false)

	const [hasBeenSeated, setHasBeenSeated] = useState<boolean>(false)

	// Handle the text translation
	const { t } = useTranslation()

	// Extract the current language from the i18next
	const language = i18n.language as LanguageTypes

	const dispatch = useDispatch()

	// Get waitlistId by url parameter
	const { waitlistId } = useParams<WaitListResponseParams>()

	// Access the globalState venueInfo
	const venueInfo = useSelector((state: RootState) => state.entities.venueInfo)

	// Position to show alert "Stay Close"
	const positionAlertStayClose = 3

	// --------------------------- HANDLES
	const handleToSmartlink = useCallback(() => {
		window.location.href = `${process.env.REACT_APP_RESERVATION_WIDGET_URL}/smartlink/${venueInfo._id}`
	}, [venueInfo._id])

	const outbackVenues = [
		'56c776ff0896b3cd13c6012b', //Bistrô Tagme (Teste)
		'65f31e31e2227b00692c5ee1', //Outback Bourbon Wallig | BZ165
		'65f3177fe2227b00692c59e0', //Outback Campina Grande | BZ163
		'663e6af9e1b0ab00544b4af5', //Outback Chapecó | BZ171
		'65e85e3ae2227b006925ea79', //Outback Itau Power | BZ167
		'663e6da6fe872f00556c003f', //Outback Maxi Jundiaí | BZ169
		'65d4ef5fda37e20071b22c62', //Outback Morumbi Town | BZ161
		'667326bf8943d856bdfa35d8', //Outback Park Sul Volta Redonda | BZ172
		'65a030940b93d7007242bb6c', //Outback Passo Fundo | BZ164
		'65a13e919ff2c900713138be', //Outback Shopping Light | BZ162
		'5f77763e52df65ed8731572e', //Outback Steakhouse Alphaville
		'64199a09ced4d4005ca5e45d', //Outback Steakhouse Alto das Nações | BZ147
		'63bec331f288dd00527440c1', //Outback Steakhouse Américas Shopping | BZ143 (copy)
		'5f74e6ef82ecb2949ed6ffa6', //Outback Steakhouse Anália Franco
		'5f7dfe4d3ebe770ad9183612', //Outback Steakhouse Aricanduva
		'5f7f5c2edd07b3b91b4caa39', //Outback Steakhouse Balneário Camboriú
		'5f74dfe2ee1a828058466a51', //Outback Steakhouse Bangu
		'5ca3aaf822db8f849ea3b8fe', //Outback Steakhouse Barra - Casinha
		'5f7f5960198a1db131037bb4', //Outback Steakhouse Barra Sul
		'5f7e0b8bc5d8150b737f797e', //Outback Steakhouse BH Shopping
		'6419a3499c155f005c2727ed', //Outback Steakhouse Blumenau Shopping Neumarkt
		'5f7e0a24a190710b52653f94', //Outback Steakhouse Bosque Maia
		'607d85197b84f128406b8eda', //Outback Steakhouse Botafogo Praia Shopping
		'5f77797d8a4df8f6fa7afc1e', //Outback Steakhouse Boulevard Bauru
		'5f807488d84384c66d82bb00', //Outback Steakhouse Boulevard Belém
		'5f7e0b25f4948a0b68bb55e6', //Outback Steakhouse Boulevard BH
		'5f73a3a3b712f795475ae0d7', //Outback Steakhouse Boulevard Rio
		'5f766db5aaeaa4030b11c36c', //Outback Steakhouse Bourbon Shopping
		'65b3f89565957a0070097d47', //Outback Steakhouse Cabo Frio | BZ168
		'628ba596e1074c00116a0b60', //Outback Steakhouse Campinas Shopping
		'5f74e15ad402588485110860', //Outback Steakhouse Campo Grande - RJ
		'5f738059fc410220024a79f2', //Outback Steakhouse Carioca
		'5f281515be0506065b0051cd', //Outback Steakhouse Casa & Gourmet
		'5f7dde0b4d1f250a97425839', //Outback Steakhouse Catarina Fashion Outlet
		'642723f956ee0d005c1c90e1', //Outback Steakhouse Catuaí Shopping Maringá | BZ148
		'62790d95b0f2b200123a2a7b', //Outback Steakhouse Caxias Shopping
		'5f766f62ec7630033736167b', //Outback Steakhouse Center Norte
		'5f7cc39f37deaf0a13b6bf33', //Outback Steakhouse CenterVale Shopping
		'6154acdd948ffa004164edb2', //Outback Steakhouse Conjunto Nacional
		'5f7f28cab8979a3c464d5db8', //Outback Steakhouse Curitiba
		'5f7e0bf4198de40b7e95cd16', //Outback Steakhouse Del Rey
		'5f7e0072ed8d2a0afa57afe8', //Outback Steakhouse DF Plaza
		'654254d6fbc78b005a7b4bd2', //Outback Steakhouse Diamond Mall | BZ159
		'5f777df5fd8b280485efdbe6', //Outback Steakhouse Dom Pedro
		'5f73a5027eb3199980de4e6a', //Outback Steakhouse Downtown
		'5f7670bee5fa5a0358dbdf17', //Outback Steakhouse Eldorado
		'5f7e10e6fccbe30baab54fd8', //Outback Steakhouse Estação Cuiabá
		'5f7f5b92959b14b72ffc06bd', //Outback Steakhouse Florianópolis
		'5f766cc275281d02f53da3ba', //Outback Steakhouse Frei Caneca
		'5f777d7639404402f5afbfc8', //Outback Steakhouse Galleria Shopping
		'5f7e01fb6317ce0b26431214', //Outback Steakhouse Goiânia
		'5f7f323732fcb52caf53758b', //Outback Steakhouse Goiânia Shopping
		'5f7cb9212c2bda40d9fb161e', //Outback Steakhouse Grand Plaza
		'5f74e1fed1e4ac86177ce5f1', //Outback Steakhouse Grande Rio Shopping
		'5f777fc6f0319109551ddfb3', //Outback Steakhouse Granja Vianna
		'5f7dfed72d4bb90ae42d0c7c', //Outback Steakhouse Guarulhos
		'62bb150a2f2a9900118de627', //Outback Steakhouse I Fashion Outlet
		'5f74e8f33c3a59998a14ef21', //Outback Steakhouse Icaraí
		'5f7f3a9a33823e41b8e8024d', //Outback Steakhouse Iguatemi Brasília
		'5f7cca5ac8cc430a3fe96149', //Outback Steakhouse Iguatemi Fortaleza
		'5f7f58aadcd672ad8aa26130', //Outback Steakhouse Iguatemi Porto Alegre
		'5fae88da6e93610036fabb10', //Outback Steakhouse Iguatemi Ribeirão Preto
		'5f7cc3ffe73f3f0a1e77d4dd', //Outback Steakhouse Iguatemi São José do Rio Preto
		'6070b06cfe08053b58823ba1', //Outback Steakhouse Ilha do Governador
		'5ff8a8e1532fc457e82058ca', //Outback Steakhouse Independência Shopping
		'5f777142115dd2df4f3693dc', //Outback Steakhouse Interlagos
		'5f7670584dc27e034dc179ac', //Outback Steakhouse Itaquera
		'5f7e01966aa0d50b1bd8ce26', //Outback Steakhouse JK Brasília
		'630e792072ae8e002b92f7d6', //Outback Steakhouse Joinville
		'5f7e0a996420090b5dfe123f', //Outback Steakhouse Jundiaí
		'64b13e3a5cfbc400550dc92d', //Outback Steakhouse Lar Center | BZ158
		'5f7376aefd690e013c8b2bcd', //Outback Steakhouse Leblon
		'62c2e08a9d3873002034dc4e', //Outback Steakhouse Limeira
		'62b5b09ad8b4f30011d842bd', //Outback Steakhouse Litoral Plaza
		'5f7f3c78bafc8246533b5bd4', //Outback Steakhouse Londrina
		'6172f562f1286c0011a6f09d', //Outback Steakhouse Madureira
		'643d788436739a00555c593f', //Outback Steakhouse Manaíra Shopping
		'5f76737507ccc4038480a4c7', //Outback Steakhouse Market Place | BZ14
		'62600761e7f24b00129b39fb', //Outback Steakhouse Mauá Plaza
		'5f77703fcb1af7dc7c0a7dba', //Outback Steakhouse Metrô Santa Cruz
		'5f73a5f74649349c8ea36340', //Outback Steakhouse Metropolitano
		'628ba58bd139d90011dbf6dc', //Outback Steakhouse Minas Shopping
		'5f7671694508c8036e665cca', //Outback Steakhouse Moema
		'62600755474a300012051d27', //Outback Steakhouse Mogi Shopping
		'5f776e3bdf088cd3f1f403a1', //Outback Steakhouse Mooca
		'5f7f4f561d8c2587836a2f51', //Outback Steakhouse Natal
		'5f7381b90848d6236efed81b', //Outback Steakhouse New York Shopping
		'5f73a46c87a5189795b7faed', //Outback Steakhouse Norte Shopping
		'5f7e0cfa2272ad0b94385fe2', //Outback Steakhouse Outlet Premium
		'64399de02ad1e4005c369644', //Outback Steakhouse Outlet Premium Brasília
		'64bfbe85e487510051b86397', //Outback Steakhouse Pantanal
		'6172f5cef9e1020011e2e2b9', //Outback Steakhouse Park Jacarepaguá
		'5f7f2f115c8d701e8b91f5c9', //Outback Steakhouse Park Shopping Barigui
		'5f7f39febb7c283feee28d69', //Outback Steakhouse Park Shopping Brasília
		'5f7cc29d6a136709fd315dea', //Outback Steakhouse Park Shopping São Caetano
		'5fc50295aac7265ec0c5c0fc', //Outback Steakhouse ParkShopping Canoas
		'64354f42131832005c649349', //Outback Steakhouse Parque das Bandeiras
		'5ff8ade3e13447581cbcd979', //Outback Steakhouse Parque Shopping Bahia
		'60be2f112fc7041de8619965', //Outback Steakhouse Parque Shopping Belém
		'5f7dde7acf16c90aa28ffcaf', //Outback Steakhouse Parque Shopping Maceió
		'5f7f389b049cc93c38bf3429', //Outback Steakhouse Passeio das Águas
		'5f766e1f37db2f031622676e', //Outback Steakhouse Pátio Higienópolis
		'5f766c53b1c1b702eaed42f3', //Outback Steakhouse Pátio Paulista
		'5f7dff90af8f8f0aef0cc9e7', //Outback Steakhouse Pátio Savassi
		'5f7f3b5274af684360205faf', //Outback Steakhouse Pier 21
		'622b5ae92c906b0011c149fd', //Outback Steakhouse Pinheiros
		'5f7cc333695d2b0a08dbd455', //Outback Steakhouse Piracicaba
		'5f74e4badcd3578e85c06f25', //Outback Steakhouse Plaza Shopping Niterói
		'5f776ef4463e5cd7bad57d3b', //Outback Steakhouse Plaza Sul
		'6661ff1aa082bb3de0589976', //Outback Steakhouse Porto Velho | BZ176
		'603e8b8f8ffb832dccb73e1d', //Outback Steakhouse Praia de Belas
		'5f7cba56bc522b4480cf24b4', //Outback Steakhouse Praiamar Santos
		'6467764cbeefc2005564d63b', //Outback Steakhouse Prudent Shopping
		'5f74dc1d3c415c748d8185bc', //Outback Steakhouse Recreio Shopping
		'5f7cb9b567141342cc4a22ac', //Outback Steakhouse Ribeirão Shopping
		'5f73783b90373007e3937acc', //Outback Steakhouse Rio Sul
		'5f7f393b0bbb123e197d06af', //Outback Steakhouse RioMar Fortaleza
		'5f7ddf4b9249770ab81e6b3e', //Outback Steakhouse Salvador Barra
		'645a5931f8307e0056c16546', //Outback Steakhouse Salvador Shopping
		'6035356260fb800b50a75d54', //Outback Steakhouse Santa Úrsula
		'5f766ff86b9ce2034243a3ac', //Outback Steakhouse Santana
		'5f7cb87d36e00c3ee3cc7058', //Outback Steakhouse Santo André
		'5f7cbb19d4747b46b466c671', //Outback Steakhouse São Bernardo
		'5f7cbc251333b94b4e77e747', //Outback Steakhouse São Bernardo Plaza
		'5f74e400df371e8ba57778c6', //Outback Steakhouse São Gonçalo
		'62b5b08ef2d4ef00117d6fd4', //Outback Steakhouse Shopping Butantã
		'5f7e0c794efaa80b89c1220d', //Outback Steakhouse Shopping Campo Grande - MS
		'60be2f221d460144ec685d47', //Outback Steakhouse Shopping Campo Limpo
		'5f766d2d9125fc030095c4be', //Outback Steakhouse Shopping Cidade São Paulo
		'622b5adfc2f1cb0011419ebe', //Outback Steakhouse Shopping Continente
		'5f7ddee3f67dbe0aadad6708', //Outback Steakhouse Shopping da Bahia
		'63f8b20bb5a9650052276e50', //Outback Steakhouse Shopping da Ilha
		'62fa7daaac2b4c00112feb0a', //Outback Steakhouse Shopping Estação BH
		'60a2c0cd25fea14b2ceb236f', //Outback Steakhouse Shopping Golden São Bernardo
		'5f777cc38846f701129e7df5', //Outback Steakhouse Shopping Iguatemi Campinas
		'5f7ddd92a79cd30a8c9f6415', //Outback Steakhouse Shopping Iguatemi Esplanada
		'5f7770d0ad4efeddfcf3503a', //Outback Steakhouse Shopping Jardim Sul
		'5fc50729b56e2b64f88af35c', //Outback Steakhouse Shopping Morumbi | BZ116
		'5f7f312206c4fc29b30804e4', //Outback Steakhouse Shopping Mueller
		'5f73842375158a2c97ed4f44', //Outback Steakhouse Shopping Nova América
		'5f74e586e77c6e90e5206c5d', //Outback Steakhouse Shopping Nova Iguaçu
		'613b546c0df350002c06522f', //Outback Steakhouse Shopping Penha
		'622b5ad12c906b0011c149ec', //Outback Steakhouse Shopping Praça da Moça
		'5f807999272d3cd5926cd7de', //Outback Steakhouse Shopping Recife
		'655cf792ab8679005a9ddfae', //Outback Steakhouse Shopping Rio Mar | BZ160
		'647dd7a545e07a00556c240b', //Outback Steakhouse Shopping Rio Poty
		'5f8076b48cd6e4ccdaa1ec2c', //Outback Steakhouse Shopping RioMar Recife
		'5f7cc9e2700e870a34948e0f', //Outback Steakhouse Shopping Tijuca
		'5f766bca511c3502df0887aa', //Outback Steakhouse Shopping Vila Olímpia
		'5f23197b085ad7259b8e01ef', //Outback Steakhouse Shopping Villa Lobos
		'63f8b9cc051cbc00520edc75', //Outback Steakhouse Shopping Villagio Caxias do Sul
		'5f776f888370edd95ad96749', //Outback Steakhouse SP Market
		'6126a78a8b7ad900202fce1b', //Outback Steakhouse Super Shopping Osasco
		'64dbd495257f1100552cd8e3', //Outback Steakhouse Suzano Shopping | BZ149
		'5f7cc45d1f1dce0a2936280f', //Outback Steakhouse Taboão da Serra
		'62fa8263de681e0011f3dc3f', //Outback Steakhouse Tacaruna
		'62a0c23a5405fe00111d48ea', //Outback Steakhouse Taguatinga Shopping
		'5f7776cc44cc79f02ecacfbe', //Outback Steakhouse Tamboré
		'63fdf61911a5ae0051d29074', //Outback Steakhouse Taquara Shopping | BZ145
		'5f766f047ee654032c0366d1', //Outback Steakhouse Tatuapé
		'5f77755feca990ea777e7fcb', //Outback Steakhouse Tietê Plaza Shopping
		'65d3ae48b4cea6006ac44194', //Outback Steakhouse Tivoli Shopping | BZ166
		'6126a79dda57340021868523', //Outback Steakhouse Tucuruvi
		'5f7e02786258600b31d878fb', //Outback Steakhouse Uberlândia
		'5f7cb7cf3063123c77aefdf8', //Outback Steakhouse União Osasco
		'5f7e010b21a6360b05d36576', //Outback Steakhouse Venâncio
		'5f74e075f523e481f7935211', //Outback Steakhouse Via Parque
		'5f7dfdbe10185a0acef61241', //Outback Steakhouse Vila Velha
		'5f7ddb7e51a8dd0a762880c5', //Outback Steakhouse Vitória
		'5f766e7a34516503219ce018', //Outback Steakhouse West Plaza		
	]

	const handleGetWaitlistStatus = useCallback(
		async (venueId: string) => {
			try {
				const responseWaitlistData = await venueManagerApi.get(`/waitlists/${waitlistId}/${venueId}/status`)

				setHasBeenCalled(false)
				setHasBeenSeated(false)

				if (responseWaitlistData?.data?.notifiedAt) {
					setHasBeenCalled(true)
					setHasBeenSeated(false)
				}

				if (responseWaitlistData?.data?.seatedAt) {
					setHasBeenSeated(true)
					setHasBeenCalled(false)
				}

				if (responseWaitlistData?.data?.canceledAt) {
					setWaitlistState(previousWaitlistState => ({
						...previousWaitlistState,
						status: null,
					}))
				}
			} catch (error) {
				Sentry.setTag('venueId', venueId)
				Sentry.captureException(new Error(ErrorsList.FAILED_TO_GET_WAITLIST_INFORMATION))
			}
		},
		[waitlistId]
	)

	// Modal handles
	const handleCloseModal = useCallback(() => {
		setModalInfo(previousModalInfoState => ({ ...previousModalInfoState, show: false }))
	}, [])

	const handleTagmeModal = (props: ModalProps) => {
		return (
			<TagmeModal
				show={props.show}
				type={props.type}
				onClose={props.onClose}
				closable={props.closable}
				closeOutsideClick={props.closeOutsideClick}
				title={props.title}
				message={props.message}
				buttons={props.buttons}
			/>
		)
	}

	const handleShowModalLeaveWaitlist = () => {
		setModalInfo({
			show: true,
			onClose: handleCloseModal,
			type: ModalTypes.noIcon,
			message: 'Would you like to leave the waitlist?',
			buttons: [
				{
					label: 'Yes',
					action: () => handleLeaveWaitlist(),
					type: ButtonTypes.primary,
					id: 'confirm_leave_waitlist_button',
				},
			],
		})
	}

	const handleGetWaitlistData = useCallback(async () => {
		try {
			const responseWithPosition = await venueManagerApi.get(`/waitlists/${waitlistId}/position`)
			const responseWithPositionData = responseWithPosition.data

			const { waitlist, waitlistSettings } = responseWithPositionData

			setWaitlistState({
				...waitlist,
				hideCustomerPosition: waitlistSettings.hiddenCustomerPosition,
				operationMode: waitlistSettings.operationMode,
			})

			handleGetWaitlistStatus(waitlist.venue)
		} catch (error) {
			setModalInfo({
				show: true,
				onClose: handleCloseModal,
				type: ModalTypes.error,
				message: 'Failed to get waitlist information.',
				buttons: [
					{
						label: 'OK',
						action: handleCloseModal,
					},
				],
			})

			Sentry.captureException(new Error(ErrorsList.FAILED_TO_GET_WAITLIST_INFORMATION_WITH_POSITION))
		}
	}, [handleCloseModal, handleGetWaitlistStatus, waitlistId])

	const handleLeaveWaitlist = useCallback(async () => {
		try {
			const data = {
				logs: { customer: waitlistState.customer?._id, actionType: 'Remove' },
				customer: waitlistState.customer?._id,
				cancelReason: 'Customer',
			}

			await venueManagerApi.put(`/waitlists/${waitlistId}/${waitlistState.venue}/cancel`, data)

			setWaitlistState(defaultWaitlist)
			handleCloseModal()
		} catch (error) {
			setModalInfo({
				show: true,
				onClose: handleCloseModal,
				type: ModalTypes.error,
				message: 'Failed to leave the waitlist.',
				buttons: [
					{
						label: 'OK',
						action: handleCloseModal,
					},
				],
			})

			Sentry.setTag('venueId', waitlistState.venue)
			Sentry.captureException(new Error(ErrorsList.FAILED_TO_LEAVE_WAITLIST))
		}
	}, [defaultWaitlist, handleCloseModal, waitlistId, waitlistState.customer?._id, waitlistState.venue])

	const handleShowMenu = async () => {
		let url = `${process.env.REACT_APP_MENU_URL}/menu/${venueInfo._id}`

		// Tratativa para integração com Olga
		if(outbackVenues.includes(venueInfo._id as string)) {
			let hashCustomer = ''
			const customerData = await venueManagerApi.get(`/Customers/${waitlistState.customer?._id}`)

			if(customerData?.data){
				const fullName = `${customerData.data.name} ${customerData.data.lastName}`
				hashCustomer = `name=${encodeURIComponent(fullName)}&phone=${encodeURIComponent(customerData.data.phone)}&email=${encodeURIComponent(customerData.data.email)}&2fa=true`
				url = `${url}?token=${btoa(hashCustomer)}`
			}
		}
		// Aqui acaba tratativa

		if (process.env.REACT_APP_NODE_ENV === 'production' && venueInfo.slug) {
			url = `${process.env.REACT_APP_TAGME_URL}/menu/${venueInfo.slug}`
		}

		window.open(url, '_blank')
	}

	const handleOnDestroy = useCallback(() => {
		if (waitlistPusher && pusherService?.channel && pusherService?.pusher) {
			pusherService.channel.unbind()
			pusherService.pusher.unsubscribe(pusherService.channel)

			setWaitlistPusherState(false)
		}
	}, [waitlistPusher])

	// --------------------------- USE EFFECTS
	// Get waitlist information from backend
	useEffect(() => {
		handleGetWaitlistData()
	}, [handleGetWaitlistData, waitlistId])

	// Update the venueInfo
	useEffect(() => {
		async function fetchData() {
			try {
				// Fetch the information available in the sessionStorage
				const { sessionStorageFilled, sessionStorageId, parsedSessionStorage } =
					handleSessionStorage('venueInfo')

				// It checks if the waitlistState is properly filled
				const venueId = waitlistState.venue

				// The conditionals bellow only will be checked if the venueInfo (global state) is not filled
				// If the sessionStorage is properly filled, the venueInfo will be set based in this information,
				// avoiding unncessary resquests
				if (sessionStorageFilled && sessionStorageId === venueId) {
					return dispatch(venueInfoUpdated(parsedSessionStorage))
				}

				// If the sessionStorage is not properly filled and the venueId is available,
				// it will fetch the necessary information in the API and update the venueInfo.
				if (venueId) {
					// Thunk Action for fetching data in the API and updating the global state venueInfo
					const successUpdateVenueInfo = (await dispatch(
						fetchVenueInfoData(venueId)
					)) as SuccessUpdateVenueInfo

					if (successUpdateVenueInfo.errorsMessages.length > 0) {
						let hasOnlyThemeError = false

						for (const errorMessage of successUpdateVenueInfo.errorsMessages) {
							hasOnlyThemeError =
								successUpdateVenueInfo.errorsMessages.length === 1 && errorMessage.includes('THEME')

							Sentry.setTag('venueId', waitlistState.venue)
							Sentry.captureMessage(errorMessage, Sentry.Severity.Warning)
						}

						if (!hasOnlyThemeError) {
							setModalInfo({
								show: true,
								closable: false,
								type: ModalTypes.noIcon,
								message: 'Waitlist is currently inaccessible.',
								buttons: [
									{
										label: 'OK',
										action: handleToSmartlink,
									},
								],
							})
						}
					}
				}
			} catch (err) {
				setModalInfo({
					show: true,
					onClose: handleCloseModal,
					type: ModalTypes.error,
					message: 'Failed to get costumer information.',
					buttons: [
						{
							label: 'OK',
							action: handleCloseModal,
						},
					],
				})
			}
		}

		// If the venueInfo is filled, we don't need to proceed, so the fetchVenueData function bellow won't be called
		if (!venueInfo._id) {
			fetchData()
		}
	}, [dispatch, handleCloseModal, handleToSmartlink, venueInfo._id, waitlistState])

	// Update Pusher
	useEffect(() => {
		if (!waitlistPusher && venueInfo?._id) {
			pusherService.subscribe(venueInfo._id)

			// add, seat, call, remove, update, schedule, removeScheduled
			const listEventsNamesUpdateWaitlist = ['remove', 'update', 'seat']

			for (const event of listEventsNamesUpdateWaitlist) {
				pusherService.channel.bind(event, () => {
					handleGetWaitlistData()
				})
			}

			pusherService.channel.bind('call', (data: any) => {
				if (data._id === waitlistId) {
					setHasBeenCalled(true)
					setHasBeenSeated(false)
				}
			})

			pusherService.channel.bind('add', (data: any) => {
				if (data._id === waitlistId) {
					handleGetWaitlistData()
				}
			})

			setWaitlistPusherState(true)
		}
	}, [handleGetWaitlistData, venueInfo, waitlistId, waitlistPusher])

	// On destroy
	useEffect(() => {
		return () => handleOnDestroy()
	}, [handleOnDestroy])
	// --------------------------- USE EFFECTS

	// HTML handles
	const handleQueuePositionPhrase = () => {
		const { _id: venueId } = venueInfo
		if (waitlistState.operationMode === OperationModeType.ADVANCED) {
			return (
				<p>
					{venuesListSpecial.includes(venueId as string)
						? t('Your position on the reserve list: ')
						: t('Your position in waitlist for a table for ')}

					<strong>
						{waitlistState.partySize} {waitlistState.partySize === 1 ? t('person') : t('people')}
					</strong>
					<br />
				</p>
			)
		}
	}

	const handleClientPosition = () => {
		if (!hasBeenCalled && !hasBeenSeated) {
			if (waitlistState.hideCustomerPosition) {
				return (
					<div id="div_hide_customer_position">
						{t(
							'You are in our online waitlist and soon you will be called. Pay attention to your SMS messages.'
						)}
					</div>
				)
			}

			if (waitlistState.status === 'active') {
				return (
					<>
						<ClientPositionStyles
							colorBackground={venueInfo.color.background || COLORS.background}
							colorMain={venueInfo.color.main || COLORS.main}
						>
							{handleQueuePositionPhrase()}
							<small>{t('Updated in real time')}</small>
							<PositionIndicator position={waitlistState.pos || waitlistState.globalPos} />
							<div>
								<p>
									{t('General Position')}:{' '}
									<span className="highlighted" data-cy="highlight1">
										{waitlistState.globalPos}º
									</span>
									<br />
									{waitlistState.customerTab ? (
										<>
											{t('Order pad')}:{' '}
											<span className="highlighted" data-cy="highlight2">
												Nº {waitlistState.customerTab}
											</span>
										</>
									) : null}
								</p>
							</div>
						</ClientPositionStyles>
					</>
				)
			}

			return (
				<ClientPositionStyles
					colorBackground={venueInfo.color.background || COLORS.background}
					colorMain={venueInfo.color.main || COLORS.main}
				>
					<div className="div_attention">
						<p>{t('You are no longer on the waitlist.')}</p>
					</div>
				</ClientPositionStyles>
			)
		}
	}

	const handleButtonLeaveWaitlist = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const isShared = urlParams.get('shared') === 'true';

		if (waitlistState.status === 'active' && !hasBeenCalled && !hasBeenSeated && !isShared) {
			return (
				<div id="div_button_leave_waitlist">
					<button
						className="outline_tagme_button"
						onClick={handleShowModalLeaveWaitlist}
						data-cy="leave_waitlist_button"
					>
						{t('Leave the waitlist')}
					</button>
				</div>
			)
		}
	}

	const showCopiedNotification = () => {
		const notification = document.createElement('div');
		notification.classList.add('copied-notification');
		notification.textContent = t('Link Copied');

		const shareButton = document.getElementById('div_button_share_waitlist');

		if (shareButton) {
			shareButton.style.position = 'relative';
			shareButton.appendChild(notification);

			notification.style.display = 'block';

			setTimeout(() => {
				notification.style.display = 'none';
				shareButton.removeChild(notification);
			}, 2000);
		}
	};

	const shareCurrentPage = async () => {
		try {
			const sharedUrl = `${window.location.href}?shared=true`;
			const venueName = venueInfo.venueName[language] || venueInfo.venueName.pt;

			if (navigator.share) {
				await navigator.share({
					title: `Minha Posição na Lista de Espera do ${venueName}`,
					text: `Confira minha posição na lista de espera do ${venueName}!`,
					url: sharedUrl
				});
			} else {
				await navigator.clipboard.writeText(sharedUrl);
				showCopiedNotification();
			}
		} catch (error) {
			const e = error as Error;

			if (e.name === 'AbortError' || e.message.includes('The user aborted a request')) {
			} else {
				setModalInfo({
					show: true,
					onClose: handleCloseModal,
					type: ModalTypes.error,
					message: 'We were unable to share your position on the waitlist. Please try again.',
				});
				Sentry.captureException(e);
			}
		}
	};

	const handleButtonShareWaitlist = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const isShared = urlParams.get('shared') === 'true';

		if (hasBeenCalled || hasBeenSeated || isShared || waitlistState.status !== 'active') {
			return null;
		}

		return (
			<div id="div_button_share_waitlist">
				<button
					className="outline_tagme_button"
					onClick={shareCurrentPage}
					data-cy="share_waitlist_button"
				>
					{t('Share Waitlist Position')}
				</button>
			</div>
		);
	};

	const handleShowAlertStayClose = () => {
		if (waitlistState.pos <= positionAlertStayClose && !hasBeenCalled && !hasBeenSeated) {
			return (
				<div className="div_attention">
					<p>
						{venuesListSpecial.includes(venueInfo._id as string)
							? `${t('Your turn is coming! Go to the establishment.')}`
							: `${t('Head to the nearby restaurant, your table should be ready soon.')}`}
					</p>
				</div>
			)
		}
	}

	const handleHasBeenCalled = () => {
		if (hasBeenCalled) {
			return (
				<div className="div_call">
					<img
						src={handleCalled}
						alt="Check Mark"
						style={{
							display: 'block',
							margin: 'auto',
						}}
					/>

					<h1>{t(`It's your turn!`)}</h1>
					<p>{t('We are waiting for you. We hope your experience is perfect!')}</p>
				</div>
			)
		}
	}

	const handleHasBeenSeated = () => {
		const { _id: venueId } = venueInfo
		if (hasBeenSeated) {
			return (
				<div className="div_seat">
					<img
						src={handleSeated}
						alt="Check Mark"
						style={{
							display: 'block',
							margin: 'auto',
						}}
					/>

					<h1>{t(`Welcome!`)}</h1>
					<p>
						{venuesListSpecial.includes(venueId as string)
							? `${t(`It's your turn in and our team is ready to better serve you.`)}`
							: `${t(`It's your turn in our restaurant and our team is ready to better serve you.`)}`}
					</p>
				</div>
			)
		}
	}

	const handleShowPriority = () => {
		if (!hasBeenCalled && !hasBeenSeated) {
			return (
				<div id="div_priority">
					{waitlistState.priority ? (
						<p>{waitlistState.displayHelpers.priorityText}</p>
					) : (
						<p>
							{t('Some customers are in preferred waitlist. It takes at least half the people to enter.')}
						</p>
					)}
				</div>
			)
		}
	}

	const handleDisclamerServiceTerms = () => {
		if (!hasBeenCalled && !hasBeenSeated) {
			return (
				<DisclamerServiceTerms
					colorMain={venueInfo.color.main}
					venueName={venueInfo.venueName[language]}
					minForm={true}
				/>
			)
		}
	}

	const handleHasMenu = () => {
		if (venueInfo.hasMenu) {
			return (
				<FooterStyles
					colorBackground={venueInfo.color.background || COLORS.background}
					colorBorder={venueInfo.color.border || COLORS.divider}
				>
					<div className="divider"></div>

					<p>{t('Have you thought about what to order?')}</p>

					<TagmeButton
						type="button"
						label={t('Take a look at our menu')}
						colorMain={venueInfo.color.main}
						disabled={false}
						handleClick={handleShowMenu}
					/>
				</FooterStyles>
			)
		}
	}

	return (
		<ContainerStyles
			colorBackground={venueInfo.color.background || COLORS.background}
			colorMain={venueInfo.color.main || COLORS.main}
			colorBorder={venueInfo.color.border || COLORS.border}
		>
			<WaitlistHeader />

			{handleClientPosition()}

			{handleButtonLeaveWaitlist()}

			{handleButtonShareWaitlist()}

			{handleShowAlertStayClose()}

			{handleShowPriority()}

			{handleDisclamerServiceTerms()}

			{handleHasBeenCalled()}

			{handleHasBeenSeated()}

			{handleHasMenu()}

			{handleTagmeModal(modalInfo)}

			<GlobalStyle colorBackground={venueInfo.color.background || '#f5f2ea'} modalOpened={modalInfo.show} />
		</ContainerStyles>
	)
}

export default WaitList
